import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { navigation, photos } from "src/data";
import Header from "components/Header";
import Main from "components/Main";
import Photos from "components/Photos";
import Button from "components/Button";
import Footer from "components/Footer";

import "styles/styles.scss";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query Galerija {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 70, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      <Header
        items={navigation}
        logo={data.logo.childImageSharp.fluid}
        logoAlt="Kudrenai sodyba logotipas"
        navPosition="right"
      />
      <Main>
        <Photos photos={photos} />
        <Button url="/kontaktai">Rezervuoti vizitą</Button>
      </Main>
      <Footer />
    </>
  );
};

export default IndexPage;
