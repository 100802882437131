import React, { useState, useCallback, useEffect, useRef } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import PropTypes from "prop-types";
import { gsap } from "gsap";

import { textTransition } from "components/Animations";

const Photos = ({ photos }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const trigger = useRef(null);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  useEffect(() => {
    const tl = new gsap.timeline();
    const { duration, from, to } = textTransition;

    tl.fromTo(trigger.current, duration, from, to);
  }, []);

  return (
    <>
      <div ref={trigger}>
        <Gallery photos={photos} direction={"column"} onClick={openLightbox} />
      </div>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
};

Photos.propTypes = {
  photos: PropTypes.array.isRequired,
};

export default Photos;
